export class  WorkOrder {
    id = 0;
    createdDate: Date;
    updatedDate: Date;
    workOrderDate: Date;
    timestamp: number = 0;
    orderNumber: number = 0;
    cityId: number = 14;
    city?: any = null;
    districtId: number = 935;
    district?: any = null;
    subscriberNumber: string;
    instantStatusId: number;
    status?: any;
    price:number=0;
    payOnPers:boolean=false;
    description: string;
    googleMaps: string;
    isDeleted: boolean;
    jobCreatorId: number;
    relationUser?: any = null;
    customerId: number;
    customer?: any = null;
    subscriberCode:any=null;
    faultTypeId: number;
    faultType?: any = null;
    faultRequestId: number;
    faultRequest?: any = null;
    workOrderStatuses?: any = null;

    customerType?:any = 1; // kurumsal - bireysel 
}

export class WorkOrderStatus{
    id = 0;
    createdDate =  new Date();
    endDate : Date;
    description: string;
    workOrderId : number;
    statusId : number;
    carId : number;
}

export class WorkOrderStatusUsers {
  id = 0;
  workOrderStatusId: number ;
  relationUserId:number;
  relationUser = null;
}