import { TimeoutError } from "rxjs";

export class User {
    email: string ;
    password: string ;
    confirmPassword?: string ;
    firstName: string;
    lastName: string;
    claims = null;
    carId = null;
    identityNumber :string;  }
  

  
  export class Status {
    id = 0;
    name : string;
    //operationClaimStatus = null;
    colorHex: string;
    order = 0;
  
  }
  
  export class UserOperationClaim {
    id = 0;
    userId = 0;
    user = null;
    operationClaimId = 0;
    operationClaim = null;
  }
  
  
  export class OperationClaimStatus {
    id = 0;
    operationClaimId = 0;
    operationClaim = null;
    statuId = 0;
    status = null;
  }

  export class UsersAddClaims {
    id = 0 ;
    userId: number;
    user = null;
    operationClaimId: number;
    operationClaim = null;
}